<template>
    <form class="countdown-email-subscription-form" novalidate @submit.prevent="submitForm">
        <div class="form-controls">
            <div class="email-input-wrap">
                <input
                    v-model="email"
                    type="email"
                    name="email"
                    :placeholder="$t('placeholders.email3')"
                    class="email-input"
                    :class="{ 'has-error': v$.email.$error }"
                    @input="onEmailInput"
                />
                <transition name="quick-fade">
                    <div v-if="v$.email.$error" class="error-tooltip">
                        <span>{{ emailError }}</span>
                    </div>
                </transition>
            </div>
            <button type="submit" class="submit-btn">
                {{ $t('buttons.signUp') }}
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import type { ICountdownEmailSubscriptionForm } from '~ui/types/components/Countdown/CoreCountdownEmailSubscriptionForm';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

const { blok } = defineProps<{ blok: ICountdownEmailSubscriptionForm }>();
const { t, locale, locales } = useI18n();
const config = useRuntimeConfig();

const localeObj = Object.values(locales.value).find((item: any) => item.code === locale.value);
const email: any = ref();

const $externalResults = ref({});

const rules = {
    email: {
        required: helpers.withMessage(t('forms.emailRequired5'), required),
        // Custom Regex Validator for email
        emailPattern: helpers.withMessage(
            () => t('forms.invalidEmail2'),
            helpers.regex(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ),
    },
};
// Setup Vuelidate
const v$ = useVuelidate(rules, { email }, { $externalResults });
const onEmailInput = () => {
    if (v$.value.email.$externalResults.length > 0) {
        $externalResults.value = [];
    }
    v$.value.$reset();
};

const submitForm = async () => {
    v$.value.$validate();

    if (!v$.value.$error) {
        await subscribeEmail({ email: email.value });
    }
};

const emailError = computed(() => {
    return v$.value.email.$errors.map((e) => e.$message)[0];
});

const handleSuccess = inject('handleSuccess');
const subscribeEmail = async ({ email }) => {
    return await $fetch(`/api/subscribe`, {
        method: 'POST',
        body: {
            email,
            listId: config?.public?.ITERABLE_LIST_ID,
            signupSource: blok.signUpSource,
            ...(localeObj?.language && locales.value.length > 1 ? { culture: localeObj.language } : {}),
        },
        onResponse({ response }) {
            if (response.ok) {
                if (handleSuccess instanceof Function) {
                    handleSuccess();
                }
            }
        },
        onResponseError({ response }) {
            if (response) {
                $externalResults.value = {
                    email: response._data?.message || blok.defaultErrorMessage,
                };
            }
        },
    }).catch((err) => {
        console.error(err);
        $externalResults.value = {
            email: blok.defaultErrorMessage,
        };
    });
};
</script>

<style scoped lang="postcss">
.form-controls {
    @apply flex flex-wrap lg:flex-nowrap gap-2 lg:justify-center;
}
.email-input-wrap {
    @apply w-full basis-full lg:basis-2/3 lg:max-w-[350px];
}
.email-input {
    @apply w-full h-10 px-3 py-2 rounded-lg border border-solid border-transparent text-slate-800 text-base;

    &.has-error {
        @apply border-error text-error;
    }
}
.submit-btn {
    @apply w-full  basis-full lg:basis-1/3 h-10 lg:max-w-[100px] rounded-lg py-3 inline-flex items-center justify-center;
    @apply bg-[#4E7DD2] text-white text-center uppercase font-bold text-xs;
}
.error-tooltip {
    @apply text-error mt-2 font-semibold text-sm text-left;
}
</style>
